:root {
    --beawards-black-text: #000000;
    --beawards-black: #303030;
    --beawards-blank: #ffffff;
    --beawards-gold: #bc903a;
}
.noSee {
    display: none !important;
}

/*******************************************
                HEADER
*******************************************/
#menuBeAwards ul li .nav-link {
    color: var(--beawards-blank);
    font-size: 18px;
    font-family: 'Montserrat-Light';
}
#menuBeAwards .active a.nav-link {
    color: var(--beawards-gold);
    font-weight: 600;
}
#menuBeAwards a.nav-link:hover {
    color: var(--beawards-gold);
}
/*******************************************
                SLIDER
*******************************************/
#sliderBeAwards {
    padding: 0px !important;
}
#sliderBeAwards img {
    width: 100%;
    float: left;
}
.slide {
    width: 100%;
    /* display: inline-block; */
    position: relative;
}
.cont-text-ad {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-77%, 52%);
    transform: translate(-77%, 52%);
    color: white;
    -webkit-transition: all 500ms ease;
    width: 38%;
}
.cont-text-ad .title-principal {
    font-family: 'MADEOuterSans-Thin';
    font-size: 3.1rem;
    color: var(--beawards-blank);
    margin-bottom: 0px !important;
}
.cont-text-ad .title-second {
    font-family: 'MADEOuterSans-regular';
    font-size: 3.5rem;
    color: var(--beawards-gold);
    line-height: 3rem;
}
.cont-text-ad p {
    width: 85%;
    color: var(--beawards-blank);
    font-family: 'Montserrat-Light';
    font-size: 1.2rem;
    margin-top: 2rem;
}
#descriptEventBE {
    display: none;
    font-family: 'MADEOuterSans-Thin';
    color: var(--beawards-blank);
    font-size: 1rem;
    margin-top: 1.5rem;
    background-color: #262626;
    margin: 0px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
#descriptEventBE p {
    position: relative;
    text-shadow: 1px 1px #262626;
    z-index: 1;
}
@media (max-width: 1611px) {
    .cont-text-ad {
        width: 53%;
        -webkit-transform: translate(-77%, 17%);
        transform: translate(-77%, 17%);
    }
    
}
@media (max-width: 1189px) {
    .cont-text-ad {
        width: 61%;
    }
    .cont-text-ad .title-principal {
        font-size: 2.1rem;
    }
    .cont-text-ad .title-second {
        font-size: 2.5rem;
    }
    .cont-text-ad p {
        font-size: 1rem;
        margin-top: 1.5rem;
    }
}
@media (max-width: 909px) {
    #descriptEventBE {
        display: block;
    }
    .cont-text-ad p {
        display: none;
    }
    .cont-text-ad {
        width: 62%;
        -webkit-transform: translate(-77%, 126%);
        transform: translate(-77%, 126%);
    }
}
@media (max-width: 731px) {
    .cont-text-ad {
        width: 100%;
        -webkit-transform: translate(-48%, 126%);
        transform: translate(-48%, 126%);
        text-shadow: 2px 2px #121212;
    }
}
@media (max-width: 477px) {
    .cont-text-ad .title-principal {
        font-size: 1.8rem;
    }
    .cont-text-ad .title-second {
        font-size: 2rem;
    }
}
@media (max-width: 405px) {
    .cont-text-ad {
        -webkit-transform: translate(-48%, 88%);
        transform: translate(-48%, 88%);
    }
}

@media (max-width: 378px) {
    .cont-text-ad .title-principal {
        font-size: 1.5rem;
    }
    .cont-text-ad .title-second {
        font-size: 1.7rem;
    }
}
/*******************************************
                FLOAT LINES
*******************************************/
#lineBeAwards {
    position: absolute;
    left: 0%;
    top: 46%;
}
/*******************************************
                FORMULARIO
*******************************************/
#contFormInscription {
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-shadow: 1px 1px #fff;
}
#contFormInscription .title-principal {
    font-family: 'MADEOuterSans-Thin';
    font-size: 3.1rem;
    color: var(--beawards-black-text);
    margin-bottom: 0px !important;
    margin-top: 7rem;
}
#contFormInscription .title-second {
    font-family: 'MADEOuterSans-regular';
    font-size: 3.5rem;
    color: var(--beawards-gold);
    line-height: 3rem;
}
#contFormInscription p {
    color: var(--beawards-black-text);
    font-family: 'Montserrat-Light';
    font-size: 1.7rem;
    margin-top: 2rem;
}
#inscription-form {
    font-family: 'MADEOuterSans-Thin';
    color: var(--beawards-black-text);
}

@media (max-width: 990px) {
    #contFormInscription .title-principal {
        margin-top: 0rem;
    }
    #contFormInscription .col-md-12.col-lg-5 {
        text-align: center;
    }
    #contFormInscription .title-principal {
        font-size: 2.5rem;
    }
    #contFormInscription .title-second {
        font-size: 3rem;
    }
    #contFormInscription p {
        font-size: 1.3rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

#social-networks-container svg {
  fill: white;
  padding: 8px;
  width: 30px;
  height: 30px;
}

#social-networks-container .icon-wrapper {
  background-color: #afafaf;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.icon-wrapper.btn-unselected {
  background-color: #afafaf;
}

.icon-wrapper.btn-selected {
  background-color: #cb9438 !important;
}

#social-networks-container .icon-wrapper:hover {
  background-color: #a3782d;
  transition: ease-in 0.1s;
  cursor: pointer;
}
/*******************************************
                FOOTER
*******************************************/
.contentSub {
    color: var(--beawards-blank);
    font-size: 1.2rem;
    font-family: 'Montserrat-Light';
}
.logofooter {
    width: 61%;
    float: left;
}
.ftr-beawards {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
}
.btn.btn-be-awards {
  background-color: transparent;
  border: 2px solid #cb9438;
  color: black;
}
.ftr-beawards .form-group.col-10 {
    padding-right: 0px;
}
.ftr-beawards .form-group.col-2 {
    padding-left: 0px;
}
.ftr-beawards .btn-be-awards-news {
    width: 100%;
    float: left;
}
.ftr-beawards .btn-be-awards-news svg path {
    fill: var(--beawards-blank);
}
.ftr-beawards .btn-be-awards-news {
    width: 100%;
    float: left;
    background-color: var(--beawards-gold);
}
#social-networks-footer svg {
    fill: var(--beawards-black-text);
    /* background-color: #afafaf; */
    padding: 8px;
    width: 35px;
    height: 35px;
}

#social-networks-footer .icon-wrapper {
    background-color: var(--beawards-blank);
    width: 35px;
    height: 35px;
    border-radius: 55%;
    /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4); */
}

#social-networks-footer .icon-wrapper.btn-unselected {
    background-color: var(--beawards-blank);
}

#social-networks-footer .icon-wrapper.btn-selected {
    background-color: #cb9438 !important;
}

#social-networks-footer .icon-wrapper:hover {
    background-color: #a3782d;
    transition: ease-in 0.1s;
    cursor: pointer;
}
#social-networks-footer .col-sn {
    padding: 9px 6px;
}
.ftr-beawards {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    background-repeat: no-repeat;
    background-position: right bottom;
}

@media (max-width: 768px) {
    .ftr-beawards .contentSub {
        text-align: center;
    }
    .logofooter {
        width: 30%;
        float: none;
        margin: 0 auto;
        display: block;
    }
    .formFooterBA {
        margin-top: 1.5rem;
    }
    #social-networks-footer {
        -ms-flex-pack: center!important;
        justify-content: center!important;
    }
}

.bgDB {
    background: #0169ac;
}

.page_logo {
    width: 60px;
}

.btn.btn-outline-secondary.btn-lg.btn-block:hover {
	background-color: #0169ac;
    color: white;
}

#buton1 {
	background-color: #0169ac;
}

#buton2 {
	background-color: #0169ac;
}

/* #buton1:hover {
    background-color: #4CAF50; 
    color: white; }
 */

.register-link.m-t-15.text-center {
	color: #798686;
    display: block;
    text-decoration: none;
}

.register-link.m-t-15.text-center:hover {
	color: white;
}

